import React from 'react'
import { Query } from '@apollo/react-components'
import gql from 'graphql-tag'
import EntityItem from '~/components/Item/EntityItem'

const TagItem = ({ globalId }) => (
  <Query
    query={gql`
      query getTag($globalId: ID!) {
        tag(id: $globalId) {
          id
          slug
          name
          thumbnail
          description
          locationName
          startDate
          endDate
        }
      }
    `}
    variables={{ globalId }}
  >
    {({ loading, data: { tag } = {} }) =>
      loading ? (
        <div />
      ) : (
        <EntityItem
          key={tag.id}
          slug={tag.slug}
          globalId={tag.id}
          thumbnail={tag.thumbnail}
          name={tag.name}
          headline={tag.description}
          location={tag.loclation}
          startYear={tag.startDate}
          endYear={tag.endDate}
        />
      )
    }
  </Query>
)

export default TagItem
