import React from 'react'
import { Query } from '@apollo/react-components'
import gql from 'graphql-tag'
import EntityItem from '~/components/Item/EntityItem'

const PostListItem = ({ globalId }) => (
  <Query
    query={gql`
      query getTrackForPostListItem($globalId: ID!) {
        postList(id: $globalId) {
          id
          slug
          name
          thumbnail
          description
          createdBy {
            id
            displayName
            slug
          }
        }
      }
    `}
    variables={{ globalId }}
  >
    {({ loading, data }) =>
      loading ? (
        <div />
      ) : (
        <EntityItem
          globalId={data.postList.id}
          slug={data.postList.slug}
          thumbnail={data.postList.thumbnail}
          name={data.postList.name}
          headline={data.postList.description}
          editor={data.postList.createdBy.displayName}
        />
      )
    }
  </Query>
)

export default PostListItem
