import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import TimeIcon from '@material-ui/icons/QueryBuilder'
import clsx from 'clsx'
import useDate from '~/hooks/useDate'
import Typo from '~/components/Typo'
import LoadBackgroundImage from '~/components/LoadBackgroundImage'
import Hidden from '@material-ui/core/Hidden'
import { useDispatch } from '~/reducers'
import { openEvent } from '~/ducks/dialog'
import { useTranslation } from '~/i18n'

interface Props {
  id?: string | null
  name?: string | null
  artwork?: string | null
  startDatetime?: string | null
  endDatetime?: string | null
  loading?: boolean
  locationName?: string | null
  author?: string | null
  entities?:
    | {
        id: string
        slug?: string | null
        name?: string | null
      }[]
    | null
  active?: boolean
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    '&:hover, $active': {
      '& $date': {
        backgroundColor: '#ff3b3a',
      },
      '& $time': {
        backgroundColor: '#c57471',
      },
      '& $name': {
        color: '#ff3b3a',
      },
      '& $eventIcon': {
        backgroundColor: '#ff3f33',
      },
    },
  },
  textPadding: {
    padding: theme.spacing(1),
  },
  roundedBox: {
    borderRadius: 5,
    overflow: 'hidden',
  },
  artwork: {
    height: 140,
    backgroundColor: theme.palette.primary.main,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 100,
    },
  },
  eventIcon: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    transitionDuration: '.25s',
    transitionProperty: 'background-color',
    padding: `2px 8px`,
    borderRadius: 5,
    lineHeight: 0,
  },
  date: {
    backgroundColor: '#1a1a1a',
    flexGrow: 1,
  },
  time: {
    backgroundColor: '#333333',
  },
  authorAndLocationBox: {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(4),
  },
  author: {},
  location: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  name: {
    fontSize: '2.2rem',
    fontWeight: 700,
    lineHeight: '1.1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  // modifiers
  active: {},
}))
const EventItem = ({
  id,
  name,
  artwork,
  loading,
  startDatetime,
  endDatetime,
  locationName,
  author,
  onMouseEnter,
  active,
}: Props) => {
  const classes = useStyles()
  const date = useDate()
  const dispatch = useDispatch()
  const { t } = useTranslation('event')
  const isNow =
    startDatetime &&
    endDatetime &&
    date(new Date()).isAfter(startDatetime) &&
    date(new Date()).isBefore(endDatetime)
  const isSoon =
    !isNow &&
    startDatetime &&
    endDatetime &&
    date(new Date()).add(1, 'hour').isAfter(startDatetime) &&
    date(new Date()).isBefore(startDatetime)
  return (
    <>
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
        onMouseEnter={onMouseEnter}
        onClick={() => id && dispatch(openEvent(id))}
      >
        <div className={classes.roundedBox}>
          {loading ? (
            <Skeleton variant="rect" className={classes.artwork} />
          ) : (
            <LoadBackgroundImage src={artwork} className={classes.artwork}>
              <span className={classes.eventIcon}>
                <TimeIcon />
              </span>
            </LoadBackgroundImage>
          )}
          <div>
            <Box display="flex" color="white">
              {isNow || isSoon ? (
                <Typo
                  variant="eventDate"
                  className={clsx(classes.date, classes.textPadding)}
                >
                  {isNow && t('event:NOW')}
                  {isSoon && t('event:SOON')}
                </Typo>
              ) : (
                <>
                  <Typo
                    variant="eventDate"
                    className={clsx(classes.date, classes.textPadding)}
                  >
                    {startDatetime && date(startDatetime).format('D MMM')}
                    {date(startDatetime).year() !== date().year() &&
                      ` ${date(startDatetime).year()}`}
                    {endDatetime &&
                      !date(startDatetime).isSame(endDatetime, 'day') &&
                      ` - ${date(endDatetime).format('D MMM')}`}
                  </Typo>
                  <Typo
                    variant="eventTime"
                    className={clsx(classes.time, classes.textPadding)}
                  >
                    {startDatetime && date(startDatetime).format('HH:mm')}
                  </Typo>
                </>
              )}
            </Box>
            <div className={classes.textPadding}>
              <Typography className={classes.name}>
                {loading ? <Skeleton animation="wave" /> : name}
              </Typography>
            </div>
            {(author || locationName) && (
              <Box
                className={clsx(
                  classes.authorAndLocationBox,
                  classes.textPadding
                )}
              >
                <Hidden smDown>
                  <div className={classes.author}>{author}</div>
                </Hidden>
                <div className={classes.location}>{locationName}</div>
              </Box>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default EventItem
