import React from 'react'
import Mobile from './Mobile'
import { useQuery } from '@apollo/react-hooks'
import { Theme, useTheme } from '@material-ui/core/styles'
import gql from 'graphql-tag'
import { getHomePage } from '~/queries'
import Head from 'next/head'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Desktop from './Desktop'

function HeaderHome() {
  const theme: Theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { data, loading } = useQuery<getHomePage>(query)

  return (
    <React.Fragment>
      <Head>
        <title key="title">Mélo</title>
        <meta property="og:title" key="og:title" content="Mélo" />
        <meta property="og:type" key="og:type" content="website" />
        {!loading && data?.pages?.edges[0]?.node?.description && (
          <meta
            property="og:description"
            key="og:description"
            content={data?.pages?.edges[0]?.node?.description}
          />
        )}
        {!loading &&
          data?.pages?.edges[0]?.node?.background?.renditions?.big?.href && (
            <meta
              property="og:image"
              key="og:image"
              content={data.pages.edges[0].node.background.renditions.big.href}
            />
          )}
        {!loading &&
          data?.pages?.edges[0]?.node?.background?.renditions?.big?.width && (
            <meta
              property="og:image:width"
              key="og:image:width"
              content={data.pages.edges[0].node.background.renditions.big.width.toString()}
            />
          )}
        {!loading &&
          data?.pages?.edges[0]?.node?.background?.renditions?.big?.height && (
            <meta
              property="og:image:height"
              key="og:image:height"
              content={data.pages.edges[0].node.background.renditions.big.height.toString()}
            />
          )}
      </Head>
      {isMobile ? <Mobile data={data} /> : <Desktop data={data} />}
    </React.Fragment>
  )
}

const query = gql`
  query getHomePage {
    pages(slug: "home") {
      edges {
        node {
          id
          description
          background {
            id
            renditions {
              big {
                id
                href
                width
                height
              }
            }
          }
        }
      }
    }
    heroSlides {
      edges {
        node {
          id
          title
          subtitle
          CTALabel
          CTATarget
          CTATargetAs
          textColor
          background {
            id
            renditions {
              mobile {
                id
                href
                width
                height
              }
              big {
                id
                href
                width
                height
              }
            }
          }
        }
      }
    }
  }
`
export default HeaderHome
