import React from 'react'
import { Query } from '@apollo/react-components'
import gql from 'graphql-tag'
import EntityItem from '~/components/Item/EntityItem'
import uniq from 'lodash/uniq'

const TrackItem = ({ globalId }) => (
  <Query
    query={gql`
      query getTrack($globalId: ID!) {
        track(id: $globalId) {
          id
          slug
          name
          thumbnail
          date
          authors {
            edges {
              node {
                id
                name
              }
            }
          }
          bands {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `}
    variables={{ globalId }}
  >
    {({ loading, data: { track } = {} }) =>
      loading ? (
        <div />
      ) : (
        <EntityItem
          key={track.id}
          globalId={track.id}
          slug={track.slug}
          thumbnail={track.thumbnail}
          name={track.name}
          startYear={track.date}
          authors={uniq([
            ...track.bands.edges.map(({ node }) => node.name),
            ...track.authors.edges.map(({ node }) => node.name),
          ])}
        />
      )
    }
  </Query>
)

export default TrackItem
