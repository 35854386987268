import React from 'react'
import { Query } from '@apollo/react-components'
import gql from 'graphql-tag'
import EntityItem from '~/components/Item/EntityItem'

const StyleItem = ({ globalId }) => (
  <Query
    query={gql`
      query getStyle($globalId: ID!) {
        style(id: $globalId) {
          id
          slug
          name
          thumbnail
          locationName
          startDate
          endDate
        }
      }
    `}
    variables={{ globalId }}
  >
    {({ loading, data: { style } = {} }) =>
      loading ? (
        <div />
      ) : (
        <EntityItem
          key={style.id}
          globalId={style.id}
          slug={style.slug}
          thumbnail={style.thumbnail}
          name={style.name}
          location={style.loclation}
          startYear={style.startDate}
          endYear={style.endDate}
        />
      )
    }
  </Query>
)

export default StyleItem
