import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import BoxPadding from '~/components/BoxPadding'
import { useQuery } from '@apollo/react-hooks'
import customBlocksQuery from './queries/customBlocks'
import { getHomePageCustomBlocks } from '~/queries'
import Typo from '~/components/Typo'
import GraphQLItem from '~/components/Item/GraphQL'
import ButtonLink from '~/components/ButtonLink'
import EventItem from '~/components/EventItem'
import Button from '@material-ui/core/Button'

const CustomBlocks = () => {
  const { data } = useQuery<getHomePageCustomBlocks>(customBlocksQuery)

  return (
    <BoxPadding>
      {data?.homePageCustomBlocks?.edges.map((edge) => {
        const subtitle = edge?.node?.subtitle || edge?.node?.collection.body
        let title = (
          <Typo variant="sectionEntityTitle">{edge?.node?.title}</Typo>
        )
        return (
          <div key={edge?.node?.id}>
            <Grid container alignItems="flex-start" justify="space-between">
              <Grid item>
                <Box mr={4} maxWidth={700}>
                  {!edge?.node?.CTATarget ? (
                    <ButtonLink
                      href={`/collection?oid=${edge?.node?.collection.id}`}
                      as={`/collection/${edge?.node?.collection.id}`}
                    >
                      {title}
                    </ButtonLink>
                  ) : (
                    title
                  )}
                  {subtitle && (
                    <Box mb={2}>
                      <Typo variant="subheading">{subtitle}</Typo>
                    </Box>
                  )}
                </Box>
              </Grid>
              {edge?.node?.CTATarget && (
                <Grid item>
                  <Button
                    component={ButtonLink}
                    variant="contained"
                    size="large"
                    color="primary"
                    href={edge?.node?.CTATarget}
                    as={edge?.node?.CTATargetAs}
                  >
                    {edge?.node?.CTALabel}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Box my={4}>
              <Grid container spacing={5}>
                {edge?.node?.collection.events?.edges.map((edge) => (
                  <Grid item xs={6} md={3} lg={2} key={edge?.node?.id}>
                    <EventItem
                      id={edge?.node?.id}
                      name={edge?.node?.name}
                      artwork={edge?.node?.thumbnail}
                      startDatetime={edge?.node?.startDatetime}
                      endDatetime={edge?.node?.endDatetime}
                      locationName={edge?.node?.locationName}
                      author={edge?.node?.authorFreetext}
                    />
                  </Grid>
                ))}
                {edge?.node?.collection.entities?.edges.map((edge) => (
                  <Grid
                    item
                    xs={edge?.node?.__typename === 'PostList' ? 12 : 6}
                    sm={edge?.node?.__typename === 'PostList' ? 12 : 4}
                    md={edge?.node?.__typename === 'PostList' ? 4 : 2}
                    key={edge?.node?.id}
                  >
                    <GraphQLItem globalId={edge?.node?.id} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>
        )
      })}
    </BoxPadding>
  )
}

export default CustomBlocks
