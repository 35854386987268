import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withDarkTheme } from '~/theme'
import Carousel from 'nuka-carousel'
import ButtonLink from '~/components/ButtonLink'
import Markdown from '~/components/Markdown'
import { makeStyles } from '@material-ui/core'
import PagingDots from './PagingDots'
import { getHomePage_heroSlides_edges_node, getHomePage } from '~/queries'

const HEIGHT = 580
const DEFAULT_TEXT_COLOR = 'white' // because of the dark theme

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: HEIGHT,
    position: 'relative',
  },
  carousel: {
    height: '100%',
    '& $slide': {
      height: HEIGHT,
    },
  },
  slide: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  body: {
    maxWidth: 800,
    padding: 20,
    [theme.breakpoints.up('md')]: {
      padding: 60,
    },
    zIndex: 1,
    '& > *': {
      marginBottom: 20,
    },
  },
  title: {
    fontSize: '2.8rem',
    fontWeight: 700,
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
}))
interface SlideProps {
  node: getHomePage_heroSlides_edges_node
  classes: ReturnType<typeof useStyles>
}
const Slide = ({
  node: {
    title,
    subtitle,
    background,
    CTALabel,
    CTATarget,
    CTATargetAs,
    textColor,
  },
  classes,
}: SlideProps) => {
  let linkIsLocal =
    CTATarget && CTATarget.startsWith('/') && !CTATarget.startsWith('//')
  return (
    <div className={classes.slide}>
      <picture className={classes.image}>
        <source
          srcSet={background?.renditions?.mobile?.href}
          media="(max-width: 500px)"
        />
        <source
          srcSet={background?.renditions?.big?.href}
          media="(min-width: 501px)"
        />
        <img alt={title} src={background?.renditions?.big?.href} />
      </picture>
      <div className={classes.body}>
        {title && (
          <Typography
            variant="h2"
            color={!textColor ? 'textPrimary' : undefined}
            style={textColor ? { color: textColor } : undefined}
            className={classes.title}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            variant="h6"
            color={!textColor ? 'textPrimary' : undefined}
            style={textColor ? { color: textColor } : undefined}
          >
            <Markdown md={subtitle} />
          </Typography>
        )}
        {CTALabel && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={linkIsLocal ? ButtonLink : 'button'}
            href={CTATarget || undefined}
            as={CTATargetAs}
          >
            {CTALabel}
          </Button>
        )}
      </div>
    </div>
  )
}
interface Props {
  data?: getHomePage
}
function DesktopHeaderHome({ data }: Props) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {data?.heroSlides?.edges && data.heroSlides.edges.length > 1 && (
        <Carousel
          speed={1500}
          easing="easeQuad"
          autoplayInterval={6000}
          autoplay
          pauseOnHover
          wrapAround
          dragging={false}
          enableKeyboardControls={false}
          className={classes.carousel}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          renderBottomCenterControls={(props) => (
            <PagingDots
              color={
                data?.heroSlides?.edges[props.currentSlide]?.node?.textColor ||
                DEFAULT_TEXT_COLOR
              }
              {...props}
            />
          )}
        >
          {data?.heroSlides?.edges?.map((edge) => (
            <Slide key={edge?.node?.id} node={edge?.node!} classes={classes} />
          ))}
        </Carousel>
      )}
      {data?.heroSlides?.edges && data.heroSlides.edges.length === 1 && (
        <Slide
          key={data?.heroSlides?.edges[0]!.node?.id}
          node={data?.heroSlides?.edges[0]!.node!}
          classes={classes}
        />
      )}
    </div>
  )
}
export default withDarkTheme(DesktopHeaderHome)
// export default compose(withDarkTheme, withStyles(styles))(HeaderHome)
