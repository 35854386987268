import React from 'react'
import withIntersectionObserver from '~/utils/withIntersectionObserver'

interface PROPS {
  isVisible: boolean
  lazyHeight: number | string
  component: React.ComponentType<any>
}

class LazyLoaderWhenVisible extends React.Component<PROPS> {
  render() {
    const { isVisible, lazyHeight, component: Component, ...props } = this.props

    if (isVisible) {
      return (
        <React.Suspense
          fallback={<div style={{ minHeight: lazyHeight }}>Chargement...</div>}
        >
          <Component {...props} />
        </React.Suspense>
      )
    }
    return <div style={{ minHeight: lazyHeight }}>Chargement...</div>
  }
}
export default withIntersectionObserver(0)(LazyLoaderWhenVisible)
