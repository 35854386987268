import React from 'react'
import { getHomePage, getHomePage_heroSlides_edges_node } from '~/queries'
import Carousel from 'nuka-carousel'
import PagingDots from './PagingDots'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Markdown from '~/components/Markdown'
import ButtonLink from '~/components/ButtonLink'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import useWindowSize from '~/hooks/useWindowSize'

interface Props {
  data?: getHomePage
}

function MobileHeaderHome({ data }: Props) {
  if (!data?.heroSlides) {
    return null
  }
  return (
    <div>
      <Carousel
        speed={1500}
        easing="easeQuad"
        heightMode="current"
        dragging={false}
        enableKeyboardControls={false}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        renderBottomCenterControls={(props) => (
          <PagingDots
            {...props}
            onClick={() => {
              window.scrollTo(0, 0)
            }}
          />
        )}
      >
        {data?.heroSlides?.edges?.map((edge) => (
          <Slide key={edge?.node?.id} node={edge?.node!} />
        ))}
      </Carousel>
    </div>
  )
}

export default MobileHeaderHome

const useSlideStyles = makeStyles((theme) => ({
  slide: {
    overflow: 'hidden',
  },
  title: {
    fontSize: '2.8rem',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  body: {
    maxWidth: 600,
    padding: 20,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: 60,
    },
    zIndex: 1,
    '& > *': {
      marginBottom: 20,
    },
  },
  CTAButton: {
    margin: 'auto',
  },
}))
interface SlideProps {
  node: getHomePage_heroSlides_edges_node
}
const Slide = ({
  node: { title, subtitle, background, CTALabel, CTATarget, CTATargetAs },
}: SlideProps) => {
  const classes = useSlideStyles()
  const { width } = useWindowSize()
  let linkIsLocal =
    CTATarget && CTATarget.startsWith('/') && !CTATarget.startsWith('//')
  let height = 100
  if (
    background?.renditions?.mobile?.width &&
    background?.renditions?.mobile?.height &&
    width
  ) {
    let ratio =
      background?.renditions?.mobile?.width /
      background?.renditions?.mobile?.height
    height = width / ratio
  }
  return (
    <div className={classes.slide}>
      <div style={{ height: height }}>
        <img
          alt={title}
          src={background?.renditions?.mobile?.href}
          style={{ width: '100%' }}
        />
      </div>
      <div className={classes.body}>
        {title && (
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="h6">
            <Markdown md={subtitle} />
          </Typography>
        )}
        {CTALabel && (
          <Box textAlign="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.CTAButton}
              component={linkIsLocal ? ButtonLink : 'button'}
              href={CTATarget || undefined}
              as={CTATargetAs}
            >
              {CTALabel}
            </Button>
          </Box>
        )}
      </div>
    </div>
  )
}
