import React from 'react'
import { fromGlobalId } from 'graphql-relay'
import TrackItem from './TrackItem'
import PersonItem from './PersonItem'
import GroupItem from './GroupItem'
import PostListItem from './PostListItem'
import InstrumentItem from './InstrumentItem'
import StyleItem from './StyleItem'
import TagItem from './TagItem'

const COMPONENTS = {
  Track: TrackItem,
  Person: PersonItem,
  PostList: PostListItem,
  Group: GroupItem,
  Instrument: InstrumentItem,
  Tag: TagItem,
  Style: StyleItem,
}
const GraphQLItem = ({ globalId, ...props }) => {
  const Component = COMPONENTS[fromGlobalId(globalId).type]
  return <Component globalId={globalId} />
}

export default GraphQLItem
