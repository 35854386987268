import { useTranslation } from '~/i18n'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'

type UseDateReturnType = (date?: any) => dayjs.Dayjs

interface Locales {
  [key: string]: boolean | undefined
}

function useDate(): UseDateReturnType {
  const [localesLoaded, setLocalesLoaded] = useState<Locales>({})
  const { i18n } = useTranslation()
  useEffect(() => {
    if (i18n.language === 'fr') {
      if (!localesLoaded.fr) {
        import('dayjs/locale/fr').then(() => {
          setLocalesLoaded({ ...localesLoaded, fr: true })
        })
      }
    }
  }, [i18n.language, localesLoaded])
  const dateFn = useCallback(
    (date) =>
      !localesLoaded[i18n.language]
        ? dayjs(date)
        : dayjs(date).locale(i18n.language),
    [i18n, localesLoaded]
  )
  return dateFn
}

export default useDate
