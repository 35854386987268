import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import { useDispatch } from 'react-redux'
import { mapLoad } from '~/ducks/map'
import SimpleSearch from '~/components/SimpleSearch'
import MapLoader from '~/components/Map/MapLoader'
import Block from './Block'
import CustomBlocks from './CustomBlocks'
import { useTranslation } from '~/i18n'

const GRID_PROPS = {
  sizes: {
    xs: 6,
    sm: 3,
    md: 2,
    lg: 2,
    xl: 2,
  },
}

const FeaturedBlocks = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('home__featured_block')
  const BLOCKS_WITH_MAP = [
    {
      component: SimpleSearch,
      title: t("Notre sélection d'artistes et groupes"),
      params: 'featured=true&type=Person&type=Group&order=?',
      grid: true,
      gridProps: GRID_PROPS,
      minHeight: 668,
    },
    {
      component: SimpleSearch,
      title: t('Notre sélection de styles'),
      params: 'featured=true&type=Style&order=?',
      grid: true,
      gridProps: GRID_PROPS,
      minHeight: 668,
    },
    {
      component: SimpleSearch,
      title: t('Notre sélection de thèmes'),
      params: 'featured=true&type=Tag&order=?',
      grid: true,
      gridProps: GRID_PROPS,
      minHeight: 668,
    },
    {
      component: SimpleSearch,
      title: t('Les derniers medias'),
      params: 'post_type=media&order=?',
      mediaGrid: true,
      playlistTitle: 'Derniers medias',
      minHeight: 1000,
    },
  ]
  const BLOCKS_WO_MAP = [
    {
      component: SimpleSearch,
      title: t('Notre sélection de morceaux'),
      params: 'featured=true&type=Track&order=?',
    },
    {
      component: SimpleSearch,
      title: t("Notre sélection d'instruments"),
      params: 'featured=true&type=Instrument&order=?',
    },
  ]
  React.useEffect(() => {
    dispatch(mapLoad(BLOCKS_WITH_MAP[0].params))
  }, [dispatch, BLOCKS_WITH_MAP])

  return (
    <div>
      <CustomBlocks />
      <Block
        params="type=PostList&featured=true&order=?"
        component={SimpleSearch}
        title={t('FEATURED_STORIES_TITLE')}
        grid
        notLazy
      />
      <Grid container>
        <Grid item sm={12} md={8}>
          {BLOCKS_WITH_MAP.map((props) => (
            <Block {...props} withMapLoader key={props.params} />
          ))}
        </Grid>
        <Hidden smDown>
          <Grid item md={4}>
            <div style={{ position: 'sticky', top: '10vh' }}>
              <MapLoader width="100%" height="80vh" />
            </div>
          </Grid>
        </Hidden>
      </Grid>
      {BLOCKS_WO_MAP.map((props) => (
        <Block {...props} key={props.params} />
      ))}
    </div>
  )
}

export default FeaturedBlocks
