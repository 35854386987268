import React from 'react'
import { Query } from '@apollo/react-components'
import gql from 'graphql-tag'
import EntityItem from '~/components/Item/EntityItem'

const GroupItem = ({ globalId }) => (
  <Query
    query={gql`
      query getGroup($globalId: ID!) {
        group(id: $globalId) {
          id
          name
          slug
          thumbnail
          startDate
          endDate
          birthPlace
        }
      }
    `}
    variables={{ globalId }}
  >
    {({ loading, data: { group } = {} }) =>
      loading ? (
        <div />
      ) : (
        <EntityItem
          key={group.id}
          globalId={group.id}
          thumbnail={group.thumbnail}
          slug={group.slug}
          name={group.name}
          location={group.birthPlace}
          startYear={group.startDate}
          endYear={group.endDate}
        />
      )
    }
  </Query>
)

export default GroupItem
