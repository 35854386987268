import gql from 'graphql-tag'

export default gql`
  fragment EventItem on Post {
    id
    postType
    startDatetime
    endDatetime
    name
    thumbnail
    locationName
    authorFreetext
  }
`
