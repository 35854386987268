import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonLink from '~/components/ButtonLink'
import { useSelector } from '~/reducers'
import { useTranslation } from '~/i18n'

const CallsToAction = () => {
  const isAuth = useSelector((state) => !!state.auth.token)
  const { t } = useTranslation('home__call_to_action')
  return (
    <Box textAlign="center">
      {!isAuth && (
        <Button
          component={ButtonLink}
          href="/login"
          variant="contained"
          size="large"
          color="primary"
        >
          {t('Créer un compte')}
        </Button>
      )}
      {isAuth && (
        <Button
          component={ButtonLink}
          href="/moi"
          variant="contained"
          size="large"
          color="primary"
        >
          {t('Voir mon profil')}
        </Button>
      )}
    </Box>
  )
}
export default CallsToAction
