import gql from 'graphql-tag'
import eventFrag from '~/queries/eventFrag'

export default gql`
  query getHomePageCustomBlocks {
    homePageCustomBlocks {
      edges {
        node {
          id
          title
          subtitle
          CTALabel
          CTATarget
          CTATargetAs
          collection {
            id
            body
            events(orderBy: "startDatetime", first: 6) {
              edges {
                node {
                  ...EventItem
                }
              }
            }
            entities(first: 12) {
              edges {
                node {
                  ... on Person {
                    id
                    slug
                  }
                  ... on Group {
                    id
                    slug
                  }
                  ... on Style {
                    id
                    slug
                  }
                  ... on Tag {
                    id
                    slug
                  }
                  ... on Instrument {
                    id
                    slug
                  }
                  ... on Track {
                    id
                    slug
                  }
                  ... on PostList {
                    id
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${eventFrag}
`
