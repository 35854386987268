import React, { ComponentType } from 'react'
import {
  HomeTopDisappearingObserver,
  HomeTopAppearingObserver,
} from './Observers'
import Typo from '~/components/Typo'
import LazyLoaderWhenVisible from '~/components/LazyLoaderWhenVisible'
import BoxPadding from '~/components/BoxPadding'

interface BlockProps {
  component: ComponentType<any>
  params: string
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  mediaGrid?: boolean
  playlistTitle?: string
  minHeight?: number
  playable?: {
    objectId: string
    title: string
  }
  withMapLoader?: boolean
  grid?: boolean
  gridProps?: {
    sizes?: {
      xs: number
      sm: number
      md: number
      lg: number
      xl: number
    }
  }
  notLazy?: boolean
}

const Block = ({
  component: Component,
  params,
  title,
  minHeight = 400,
  withMapLoader,
  notLazy,
  ...props
}: BlockProps) => {
  return (
    <BoxPadding key={params}>
      <HomeTopDisappearingObserver
        title={title}
        withMapLoader={withMapLoader}
        params={params}
      />
      <div style={{ minHeight }}>
        {notLazy ? (
          <Component
            title={<Typo variant="sectionEntityTitle">{title}</Typo>}
            params={params}
            {...props}
          />
        ) : (
          <LazyLoaderWhenVisible
            lazyHeight={minHeight}
            component={Component}
            title={<Typo variant="sectionEntityTitle">{title}</Typo>}
            params={params}
            {...props}
          />
        )}
      </div>
      <HomeTopAppearingObserver
        title={title}
        withMapLoader={withMapLoader}
        params={params}
      />
    </BoxPadding>
  )
}

export default React.memo(Block)
