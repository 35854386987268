import React from 'react'
import { Query } from '@apollo/react-components'
import gql from 'graphql-tag'
import EntityItem from '~/components/Item/EntityItem'

const InstrumentItem = ({ globalId }) => (
  <Query
    query={gql`
      query getInstrument($globalId: ID!) {
        instrument(id: $globalId) {
          id
          slug
          name
          thumbnail
        }
      }
    `}
    variables={{ globalId }}
  >
    {({ loading, data: { instrument } = {} }) =>
      loading ? (
        <div />
      ) : (
        <EntityItem
          key={instrument.id}
          globalId={instrument.id}
          slug={instrument.slug}
          thumbnail={instrument.thumbnail}
          name={instrument.name}
        />
      )
    }
  </Query>
)

export default InstrumentItem
