import React from 'react'
import Observer from '@researchgate/react-intersection-observer'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const SENTINEL_HEIGHT = 600

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  sentinel: {
    width: 100,
    height: SENTINEL_HEIGHT,
    position: 'absolute',
    top: -SENTINEL_HEIGHT,
    zIndex: -1,
  },
  debug: {
    border: '1px solid green',
    zIndex: 999,
  },
}))

const SentinelObserver = React.forwardRef<
  HTMLDivElement,
  { offsetTop?: number; debug?: boolean }
>(({ offsetTop, debug }, ref?) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div
        ref={ref}
        className={clsx(classes.sentinel, { [classes.debug]: debug })}
        style={{
          width: 100,
          height: SENTINEL_HEIGHT,
          position: 'absolute',
          top:
            offsetTop == null || isNaN(offsetTop)
              ? undefined
              : -SENTINEL_HEIGHT + offsetTop,
        }}
      />
    </div>
  )
})

export interface Props {
  offsetTop?: number
  debug?: boolean
  onChange: () => void
}

export const TopDisappearingObserver = ({
  offsetTop,
  onChange,
  ...props
}: Props) => {
  return (
    <Observer
      onChange={({ isIntersecting, boundingClientRect }) => {
        // disapearing at the top
        if (!isIntersecting && boundingClientRect.bottom <= 0) {
          onChange()
        }
      }}
    >
      <SentinelObserver
        offsetTop={
          offsetTop != null && !Number.isNaN(offsetTop) ? offsetTop : -150
        }
        {...props}
      />
    </Observer>
  )
}

export const TopAppearingObserver = ({
  offsetTop,
  onChange,
  ...props
}: Props) => {
  return (
    <Observer
      onChange={({ isIntersecting, boundingClientRect }) => {
        // appearing at the top
        if (
          isIntersecting &&
          boundingClientRect.top <= 0 &&
          boundingClientRect.bottom > 0
        ) {
          onChange()
        }
      }}
    >
      <SentinelObserver
        offsetTop={
          offsetTop != null && !Number.isNaN(offsetTop) ? offsetTop : -300
        }
        {...props}
      />
    </Observer>
  )
}
