import React from 'react'
import HeaderHome from '~/components/HeaderHome'
import Box from '@material-ui/core/Box'
import CallsToAction from './CallsToAction'
import FeaturedBlocks from './FeaturedBlocks'
import { HomeTopAppearingObserver } from './Observers'

const Home = () => {
  return (
    <Box mb={12}>
      <Box mb={6}>
        <HomeTopAppearingObserver title={''} offsetTop={200} />
        <HeaderHome />
      </Box>
      <FeaturedBlocks />
      <CallsToAction />
    </Box>
  )
}

export default Home
