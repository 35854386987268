import React from 'react'
import Home from '~/components/Home'
import Layout from '~/components/Layout'

export default function HomePage() {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}
HomePage.getInitialProps = async () => {
  return { namespacesRequired: ['home__featured_block'] }
}
