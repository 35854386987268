import React from 'react'

export const getDotIndexes = (
  slideCount,
  slidesToScroll,
  slidesToShow,
  cellAlign,
  scrollMode
) => {
  const dotIndexes = []
  let lastDotIndex = slideCount - slidesToShow

  switch (cellAlign) {
    case 'center':
    case 'right':
      lastDotIndex += slidesToShow - 1
      break
    default:
      break
  }

  if (lastDotIndex < 0) {
    return [0]
  }

  for (let i = 0; i < lastDotIndex; i += slidesToScroll) {
    dotIndexes.push(i)
  }

  if (cellAlign === 'left' && scrollMode === 'page') {
    lastDotIndex = Math.floor(
      slideCount - (slideCount % slidesToShow || slidesToShow)
    )
  }

  dotIndexes.push(lastDotIndex)
  return dotIndexes
}

const PagingDots = (props) => {
  const getListStyles = () => ({
    position: 'relative',
    top: -10,
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  })

  const getButtonStyles = (active) => ({
    cursor: 'pointer',
    opacity: active ? 1 : 0.5,
    background: 'transparent',
    border: 'none',
    fill: 'black',
  })

  const indexes = getDotIndexes(
    props.slideCount,
    props.slidesToScroll,
    props.slidesToShow,
    props.cellAlign,
    props.scrollMode
  )

  const {
    pagingDotsContainerClassName,
    pagingDotsClassName,
    pagingDotsStyle = {},
  } = props.defaultControlsConfig
  return (
    <ul className={pagingDotsContainerClassName} style={getListStyles()}>
      {indexes.map((index) => {
        const isActive = props.currentSlide === index

        return (
          <li
            key={index}
            className={isActive ? 'paging-item active' : 'paging-item'}
          >
            <button
              className={pagingDotsClassName}
              type="button"
              style={{
                ...getButtonStyles(isActive),
                ...pagingDotsStyle,
              }}
              onClick={() => {
                props.goToSlide(index)
                props.onClick && props.onClick(index)
              }}
              aria-label={`slide ${index + 1} bullet`}
            >
              <svg className="paging-dot" width="6" height="6">
                <circle cx="3" cy="3" r="3" style={{ fill: props.color }} />
              </svg>
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default PagingDots
