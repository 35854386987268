import React from 'react'
import { useDispatch } from 'react-redux'
import { setHeader } from '~/ducks/header'
import { mapLoad } from '~/ducks/map'
import { Dispatch } from 'redux'
import {
  TopAppearingObserver,
  TopDisappearingObserver,
  Props as ObserverProps,
} from '~/components/Observers'

interface Props {
  offsetTop?: ObserverProps['offsetTop']
  title: string | JSX.Element
  params?: string
  withMapLoader?: boolean
}
const loadHeaderAndMap = ({
  title,
  params,
  withMapLoader,
  dispatch,
}: Props & { dispatch: Dispatch<any> }) => {
  dispatch(
    setHeader({
      title,
    })
  )
  if (params && withMapLoader) {
    dispatch(mapLoad(params))
  }
}
export const HomeTopDisappearingObserver = ({ offsetTop, ...props }: Props) => {
  const dispatch = useDispatch()
  return (
    <TopDisappearingObserver
      offsetTop={offsetTop}
      onChange={() => {
        loadHeaderAndMap({ ...props, dispatch })
      }}
    />
  )
}

export const HomeTopAppearingObserver = ({ offsetTop, ...props }: Props) => {
  const dispatch = useDispatch()
  return (
    <TopAppearingObserver
      offsetTop={offsetTop}
      onChange={() => {
        loadHeaderAndMap({ ...props, dispatch })
      }}
    />
  )
}
