import React from 'react'
import { Query } from '@apollo/react-components'
import gql from 'graphql-tag'
import EntityItem from '~/components/Item/EntityItem'

const PersonItem = ({ globalId }) => (
  <Query
    query={gql`
      query getPerson($globalId: ID!) {
        person(id: $globalId) {
          id
          slug
          name
          thumbnail
          birthDate
          deathDate
          birthPlace
        }
      }
    `}
    variables={{ globalId }}
  >
    {({ loading, data: { person } = {} }) =>
      loading ? (
        <div />
      ) : (
        <EntityItem
          key={person.id}
          slug={person.slug}
          globalId={person.id}
          thumbnail={person.thumbnail}
          name={person.name}
          location={person.birthPlace}
          startYear={person.birthDate}
          endYear={person.deathDate}
        />
      )
    }
  </Query>
)

export default PersonItem
